import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of ,  Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'
import { API_BENEFICIARY_URL } from '../connection.data';
import { QueryParamsModel, QueryResultsModel } from '../_base/crud';
import { BasicService } from '../common/services/basic.service';

@Injectable()
export class BeneficiaryService{
    baseUrl: string;
    constructor(
        protected http: HttpClient
    ) { 
        // super(http);
        this.baseUrl = API_BENEFICIARY_URL;
        console.log("HTTP BENEFICIARY:");
        console.log(http);
        console.log(this.http)
    }
    
    getPage(query:any):any{
        return of(null).pipe(
            switchMap(()=>this.http.post(`${this.baseUrl}/page`, query))
        )
    }

    issuesPage(query: QueryParamsModel): Observable<QueryResultsModel>{
        console.log("ISSUES PAGE HTTP");
        console.dir(this.http);
        return of(null).pipe(
            switchMap(()=>this.http.post(`${this.baseUrl}/issues`, query))
        ) as any
    }

    getAll(options: any){
        return of(null).pipe(
            switchMap(()=>this.http.get(`${this.baseUrl}/all?${Object.keys(options).map(key=>key+'='+options[key]).join('&')}`)),
            map((response:any)=>{
                return response.rows;
            })
        )
    }
}